import { useState, useEffect } from "react";
import { Button, Checkbox, Form, type FormProps, Input, Switch } from "antd";
import { loginAdmin, registerAdmin } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import { isValidEmail } from "helpers/helper";

import styles from "./index.module.css";

type FieldType = {
  email?: string;
  password?: string;
  remember?: boolean;
  firstName?: string;
  lastName?: string;
  registration: boolean;
};

const LoginPage: React.FC = () => {
  const [form] = Form.useForm<FieldType>();
  const [isRegistration, setIsRegistration] = useState(false);
  const [error, setError] = useState("");
  const { loading, run: login } = useRequest(loginAdmin, {
    manual: true,
    onError: (error: any) => {
      setError(error.response?.data?.message || "wrong user name or password");
    },
    onSuccess: (data) => {
      if (data.access_token) {
        if (form.getFieldValue("remember")) {
          localStorage.setItem("accessToken", data.access_token);
        } else {
          sessionStorage.setItem("accessToken", data.access_token);
        }
        window.location.href = "/";
      }
    },
  });
  const { loading: registerLoading, run: register } = useRequest(registerAdmin, {
    manual: true,
    onError: (error: any) => {
      setError(error.response?.data?.message || "something went wrong");
    },
    onSuccess: (response) => {
      form.setFieldValue("email", response.email);
      form.setFieldValue("password", "");
      form.setFieldValue("registration", false);
      setIsRegistration(false);
    },
  });
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    setError("");
    if (form.getFieldValue("registration")) {
      register(values.email, values.password, values.firstName, values.lastName);
    } else {
      // login("admin@c2c.com", "qwerqwer");
      login(form.getFieldValue("email"), form.getFieldValue("password"));
    }
  };
  useEffect(() => {
    setError("");
  }, [isRegistration]);

  return (
    <div className={styles.container}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true, registration: false, email: "", password: "", firstName: "", lastName: "" }}
        style={{ width: 500 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: "invalid email",
            },
            () => ({
              validator(_, value) {
                if (!value || isValidEmail(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("invalid email"));
              },
            }),
          ]}
          label="Username"
          name="email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>
        {isRegistration ? (
          <>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input />
            </Form.Item>
          </>
        ) : null}
        <div className={styles.error}>
          <Form.ErrorList errors={[error]} />
        </div>
        {isRegistration ? null : (
          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 12 }}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        )}
        <Form.Item label="Registration" name="registration" valuePropName="checked" wrapperCol={{ offset: 0 }}>
          <Switch onChange={(value) => setIsRegistration(value)} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading || registerLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
