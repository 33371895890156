export const getArraysProductData = (array: Array<any>) => {
  const elements = array.map((value) => {
    if (value instanceof Object) {
      return Object.entries<[key: string, value: string]>(value).map((value) => {
        return (
          <div key={value[0] + value[1]}>
            <div>
              {value[0]}: {value[1]}
            </div>
          </div>
        );
      });
    } else {
      return <div key={value}>{value}</div>;
    }
  });

  return elements;
};
