import { Button, Divider, Form, Modal, Select, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { useRequest } from "ahooks";
import { getProduct, getUserById, updateProductStatus } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import TextArea from "antd/es/input/TextArea";
import { AdminContext } from "context/adminContext";
import CarouselItem from "./Carousel";

import styles from "./index.module.css";
import { getArraysProductData } from "./utils";
type Props = {
  isModalOpen: boolean;
  refresh: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ad: any;
};

const getDescription = (item) => {
  switch (item.key) {
    case "unavailableWeekdays":
      return { ...item, children: item.children?.length ? item.children.join(" ") : "" };
    case "unavailableDates":
      return { ...item, children: item.children?.length ? item.children.join(" ") : "" };
    case "productAddress":
      return { ...item, children: item.children?.address };
    case "user":
      return { ...item, children: "" };
    case "address":
      return { ...item, children: item.children?.address };
    case "photos":
      return null;
    case "productOption":
      return null;
    case "productGroup":
      return { ...item, children: item.children?.translations[0].name };
    default:
      return item;
  }
};

const getItems = (data) => {
  const response = data
    ?.map((el) => {
      if (!el[0]) {
        return null;
      }

      const data = { key: el[0], label: el[0], children: el[1] };
      return data;
    })
    .filter((el) => el.children)
    .map((el) => getDescription(el))
    .filter(Boolean);

  return response;
};

const getUserInfoData = (userInfo) => {
  return {
    name: userInfo.firstName + userInfo.lastName,
    email: userInfo.email,
    address: userInfo.address,
    phone: userInfo.phone,
  };
};

const ActionModal = ({ isModalOpen, setIsModalOpen, refresh, ad }: Props) => {
  console.log("S");
  const [form] = Form.useForm();
  const { localCountry } = useContext(AdminContext);
  const [userId, setUserId] = useState("");
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { loading: updateLoading, run: update } = useRequest(updateProductStatus, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "status was updated");
      refresh();
      setIsModalOpen(false);
      form.resetFields();
    },
  });
  const { loading: getProductLoading, data } = useRequest(() => getProduct(ad.id, localCountry.iso), {
    ready: !!ad && !!localCountry,
    refreshDeps: [ad],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: (data) => {
      console.log(data);
    },
  });
  const { data: userInfo } = useRequest(() => getUserById(userId, localCountry.iso), {
    ready: !!userId && !!localCountry,
    refreshDeps: [userId, localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const onFinish = (values) => {
    update(ad.id, { status: values.status, comment: values.comment }, localCountry.iso);
  };

  useEffect(() => {
    if (data) {
      setUserId(data.userId);
    }
  }, [data]);

  useEffect(() => {
    if (ad) {
      form.setFieldValue("status", ad.status);
    }
  }, [form, ad]);
  return (
    <Modal title="Product info" open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
      {getProductLoading || !data ? (
        <Spin />
      ) : (
        <>
          <div className={styles.container}>
            <CarouselItem photos={data.photos} />
            {getItems(Object.entries(data)).map((el) => (
              <div key={el.key} className={styles.itemContainer}>
                <div className={styles.itemHeader}>{el.key}:</div>
                <div className={styles.itemContent}>
                  {Array.isArray(el.children) ? getArraysProductData(el.children) : el.children}
                </div>
              </div>
            ))}
            <Divider />
            <h3 className={styles.itemHeader}>User info</h3>
            {userInfo ? (
              Object.entries(getUserInfoData(userInfo)).map(([key, value]) => (
                <div key={key} className={styles.itemContainer}>
                  <div className={styles.itemHeader}>{key}:</div>
                  <div className={styles.itemContent}>{value}</div>
                </div>
              ))
            ) : (
              <Spin />
            )}
          </div>
          <Form layout="vertical" form={form} className={styles.form} onFinish={onFinish}>
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "type is required",
                },
              ]}
            >
              <Select
                placeholder="status"
                style={{ width: "200px" }}
                options={[
                  { label: "Draft", value: "Draft" },
                  { label: "Pending", value: "Pending" },
                  { label: "Published", value: "Published" },
                  { label: "Corrections", value: "Corrections" },
                  { label: "Archive", value: "Archive" },
                ]}
              />
            </Form.Item>
            <Form.Item name="comment" label="Comment">
              <TextArea rows={2} />
            </Form.Item>
            <div className={styles.buttonsWrapper}>
              <Button style={{ marginRight: 20 }} type="primary" htmlType="submit" loading={updateLoading}>
                Update
              </Button>
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default ActionModal;
