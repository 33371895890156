import { useContext, useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useRequest } from "ahooks";
import { editCity } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Props = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  selectedCity: any;
  refreshCities: () => void;
};

const EditCityModal = ({ isModalOpen, setIsModalOpen, selectedCity, refreshCities }: Props) => {
  const [form] = Form.useForm();
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const { loading: editLoading, run: editCityHandler } = useRequest(
    (address: string, price: string) => editCity(localCountry.iso, selectedCity.id, address, +price),
    {
      manual: true,
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
      },
      onSuccess: () => {
        openNotificationWithIcon("success", "city was updated");
        refreshCities();
        setIsModalOpen(false);
      },
    },
  );

  useEffect(() => {
    if (selectedCity) {
      form.setFieldValue("address", selectedCity.address);
      form.setFieldValue("deliveryPrice", +selectedCity.deliveryPrice);
    }
  }, [form, selectedCity]);

  if (!selectedCity) return null;

  return (
    <Modal
      className={styles.modal}
      style={{ top: "30px" }}
      title="Update city"
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <div className={styles.container}>
        <Form
          form={form}
          className={styles.inputWrapper}
          onFinish={(values) => editCityHandler(values.address, values.deliveryPrice)}
        >
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "address is required",
              },
            ]}
          >
            <Input type="text" placeholder="address" />
          </Form.Item>
          <Form.Item
            name="deliveryPrice"
            label="Delivery price"
            rules={[
              {
                required: true,
                message: "Price is required",
              },
            ]}
          >
            <Input type="text" placeholder="address" />
          </Form.Item>
          <Form.Item>
            <Button disabled={!selectedCity.id} type="primary" htmlType="submit" loading={editLoading}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditCityModal;
