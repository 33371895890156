import { useContext } from "react";
import { Button, Descriptions, Divider, Popconfirm, Skeleton } from "antd";
import { useRequest } from "ahooks";
import { NotificationContext } from "context/notificationContext";
import { approveContract, declineContract, getApproveContract, getContract } from "helpers/backend_helper";
import { RetweetOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import MintContract from "./Mint";
import BurnContract from "./Burn";
import TransferContract from "./Transfer";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

const getItems = (data) => {
  return data?.map((el) => ({ key: el[0], label: el[0], children: el[1] }));
};

const Contract = () => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { accountData, localCountry } = useContext(AdminContext);
  const { loading, data } = useRequest(getContract, {
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const { loading: declineLoading, run: decline } = useRequest(declineContract, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => getApprove(),
  });
  const { loading: approveLoading, run: approve } = useRequest(approveContract, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => getApprove(),
  });
  const {
    loading: getApproveLoading,
    data: approveData,
    run: getApprove,
  } = useRequest(getApproveContract, {
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const hasWallet = !!accountData.userWallets.length;

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Contract
      </Divider>
      {loading || !data ? (
        <Skeleton active />
      ) : (
        <div>
          <Descriptions
            column={{ xs: 2, sm: 2, md: 2, xl: 2, lg: 2, xxl: 2 }}
            bordered
            title="Contract Info"
            items={getItems(Object.entries(data))}
          />
          <div className={styles.buttonsWrapper}>
            <TransferContract disabled={!hasWallet} />
            <MintContract disabled={!hasWallet} />
            <BurnContract disabled={!hasWallet} />
          </div>
          <Divider orientation="left" className={styles.divider}>
            Approve transaction
          </Divider>
          {!!approveData && (
            <Descriptions
              column={{ xs: 2, sm: 2, md: 2, xl: 2, lg: 2, xxl: 2 }}
              bordered
              title="Contract Info"
              items={getItems(
                [
                  ["initiator", approveData.initiator],
                  ["type", approveData.type],
                  ["orderAddress", approveData.orderAddress],
                ].concat(Object.entries(approveData.body)),
              )}
            />
          )}
          <div className={styles.buttonsWrapper}>
            <Button
              loading={getApproveLoading}
              type="primary"
              style={{ color: "#000000", fontFamily: "Mont", marginTop: "20px" }}
              size="large"
              onClick={() => getApprove()}
              icon={<RetweetOutlined />}
            >
              Refresh
            </Button>
            {accountData?.userWallets[0] ? (
              <Popconfirm
                title="Approve"
                description="Are you sure to approve this contract?"
                onConfirm={() => approve(localCountry.iso)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  loading={approveLoading}
                  type="primary"
                  style={{ color: "#000000", fontFamily: "Mont", marginTop: "20px" }}
                  size="large"
                  icon={<CheckCircleOutlined />}
                >
                  Approve
                </Button>
              </Popconfirm>
            ) : null}
            <Popconfirm
              title="Delete"
              description="Are you sure to decline this contract?"
              onConfirm={() => decline(localCountry.iso)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                loading={declineLoading}
                type="primary"
                style={{ color: "#000000", fontFamily: "Mont", marginTop: "20px" }}
                size="large"
                danger
                icon={<CloseCircleOutlined />}
              >
                Decline
              </Button>
            </Popconfirm>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contract;
