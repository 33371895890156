import { useContext, useMemo, useState } from "react";
import { Button, Divider, Popconfirm, Table, Tag, Tooltip } from "antd";
import { useRequest } from "ahooks";
import { NotificationContext } from "context/notificationContext";
import { deleteCountry, getLanguages } from "helpers/backend_helper";
import NewCountryModal from "./Components/NewModal";
import { CheckCircleOutlined } from "@ant-design/icons";
import EditCountryModal from "./Components/EditModal";
import { Language } from "../Languages";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

export type Country = {
  active: boolean;
  id: string;
  iso: string;
  name: string;
  wallet: string;
  languages: Language[];
  defaultLanguageId: number;
  defaultLanguage: Language;
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "ISO",
    dataIndex: "iso",
    key: "iso",
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
  },
  {
    title: "Wallet",
    dataIndex: "wallet",
    key: "wallet",
  },
  {
    title: "Languages",
    dataIndex: "languages",
    key: "languages",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];

const Countries = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>(null);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { countries, refreshCountries, localCountry } = useContext(AdminContext);
  const { data: languages } = useRequest(getLanguages, {
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const { loading: deleteLoading, run: deleteCountryHandler } = useRequest(deleteCountry, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "country was deleted");
      refreshCountries();
    },
  });

  const source = useMemo(
    () =>
      countries?.map((el, i) => ({
        ...el,
        key: i,
        id: (
          <Tooltip title={el.id}>
            <div>{`${el.id.slice(0, 5)}...`}</div>
          </Tooltip>
        ),
        active: (
          <div>
            {el.active ? (
              <Tag icon={<CheckCircleOutlined />} color="success">
                active
              </Tag>
            ) : null}
          </div>
        ),
        languages: el.languages?.map((language) => (
          <Tag key={language.id} color="success">
            {language.name}
          </Tag>
        )),
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setSelectedCountry(el);
                setIsModalOpen(true);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title={`Delete ${el.name}`}
              description="Are you sure to delete this country?"
              onConfirm={() => deleteCountryHandler(el.id, localCountry.iso)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger loading={deleteLoading}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        ),
      })) || [],
    [countries, deleteCountryHandler, deleteLoading],
  );

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Countries
      </Divider>
      <Table dataSource={source} columns={columns} className={styles.table} pagination={false} />
      <div className={styles.buttonContainer}>
        <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
          Add new country
        </Button>
      </div>
      <EditCountryModal
        refresh={refreshCountries}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedCountry={selectedCountry}
        languages={languages}
      />
      <NewCountryModal
        languages={languages}
        refresh={refreshCountries}
        setIsCreateModalOpen={setIsCreateModalOpen}
        isCreateModalOpen={isCreateModalOpen}
      />
    </div>
  );
};

export default Countries;
