import { Spin, Upload, UploadFile, UploadProps } from "antd";
import { PropsWithChildren, useEffect } from "react";

import { PaperClipIcon } from "Common/Icons/PaperClip";
import { SpinnerIcon } from "Common/Icons/Spinner";

import styles from "./index.module.css";

type Props = {
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  fileList: UploadFile[];
  abortDataRef: React.MutableRefObject<Array<{ name: string; abortController: AbortController }>>;
};

export const UploadFilesComponent = (props: PropsWithChildren<Props>) => {
  const { children, setFileList, fileList, abortDataRef, setIsUploading } = props;

  useEffect(() => {
    if (fileList.find((file) => file.status === "uploading")) setIsUploading(false);
  }, [fileList, setIsUploading]);
  const uploadPropsFile: UploadProps = {
    multiple: true,
    onChange: (data) => {
      setFileList(data.fileList);
    },
    onRemove: (file) => {
      setFileList((prev) => {
        const sliced = prev.slice();
        const files = sliced.filter((fileLoad) => fileLoad.originFileObj.name !== file.originFileObj.name);
        abortDataRef.current
          .find((value) => value.name === file.originFileObj.name)
          .abortController.abort("user action");
        return files;
      });
    },
    iconRender: function (file) {
      if (file.status === "uploading") return <Spin indicator={<SpinnerIcon />} spinning size="small" />;
      return <PaperClipIcon />;
    },
    beforeUpload: () => false,
    fileList,
  };

  return (
    <Upload {...uploadPropsFile} className={styles.uploadFiles}>
      {children}
    </Upload>
  );
};
