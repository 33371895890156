import { Button, Form, Modal, Spin } from "antd";
import { useContext, useEffect } from "react";
import { useRequest } from "ahooks";
import { getGroupById, updateGroupById } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import LanguageForm from "pages/Main/Components/LanguageForm";

import styles from "./index.module.css";

type Props = {
  isModalOpen: boolean;
  refresh: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
};

const EditGroupModal = ({ isModalOpen, setIsModalOpen, refresh, id }: Props) => {
  const [form] = Form.useForm();
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { loading: updateLoading, run: update } = useRequest(updateGroupById, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "group was updated");
      refresh();
      setIsModalOpen(false);
    },
  });
  const { localCountry } = useContext(AdminContext);
  const languages = localCountry?.languages;
  const {
    loading: getLoading,
    data: selectedGroup,
    run,
  } = useRequest(() => getGroupById(id.toString(), localCountry?.iso), {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  useEffect(() => {
    if (id) {
      run();
    }
  }, [id, run]);

  useEffect(() => {
    if (selectedGroup) {
      selectedGroup.translations?.map((el) => {
        form.setFieldValue(`name${el.languageCode}`, el.name);
        form.setFieldValue(`description${el.languageCode}`, el.description);
      });
      form.setFieldValue("categories", "");
    }
  }, [form, selectedGroup]);

  if (!selectedGroup) {
    return null;
  }

  return (
    <Modal title="Edit group" open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
      <Form
        layout="vertical"
        form={form}
        className={styles.form}
        initialValues={selectedGroup}
        onFinish={(values) => {
          const groups = languages?.map((language) => {
            const nameKey = `name${language.code}`;
            const descriptionKey = `description${language.code}`;
            return { name: values[nameKey], description: values[descriptionKey], languageCode: language.code };
          });
          update(groups, localCountry?.iso, selectedGroup.id);
        }}
      >
        {getLoading ? (
          <div className={styles.spin}>
            <Spin />
          </div>
        ) : (
          <>
            {languages?.map((language) => <LanguageForm key={language?.id} language={language} />)}
            <div className={styles.buttonsWrapper}>
              <Button style={{ marginRight: 20 }} type="primary" htmlType="submit" loading={updateLoading}>
                Update
              </Button>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EditGroupModal;
