import { useContext, useEffect } from "react";
import { AdminContext } from "context/adminContext";

const AuthGuard = ({ children }) => {
  const { isAuthorized } = useContext(AdminContext);

  useEffect(() => {
    if (!isAuthorized) {
      window.location.href = "/login";
    }
  }, [isAuthorized]);

  return children;
};

export default AuthGuard;
