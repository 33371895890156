import { Button, Input, Spin, UploadFile } from "antd";
import { createRef, useEffect, useRef, useState } from "react";
import { Chat as ChatType } from "context/adminContext";
import dayjs from "dayjs";

import styles from "./Chat.module.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { UploadFilesComponent } from "Components/UploadFilesComponent";
import { PaperClipIcon } from "Common/Icons/PaperClip";
import { postFileChat, postFileSupportChat } from "helpers/backend_helper";
import { AxiosRequestConfig } from "axios";

type Props = {
  chatId: string;
  sendSocketMessage: (content: string, chatId: string) => void;
  loading: boolean;
  cursor: number;
  chatData: ChatType;
  loadMore: (cursor: number) => void;
  hasMore: boolean;
  isDebateChat: boolean;
};

export const Chat = ({
  chatId,
  chatData,
  sendSocketMessage,
  loading,
  loadMore,
  cursor,
  hasMore,
  isDebateChat,
}: Props) => {
  const [textMessage, setTextMessage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const abortDataRef = useRef<Array<{ name: string; abortController: AbortController }>>([]);
  const divRef = createRef<HTMLDivElement>();
  const handleSendFile = () => {
    if (!fileList.length) return;

    for (const file of fileList) {
      if (file.status === "error") continue;
      const fmData = new FormData();
      const abortController = new AbortController();
      abortDataRef.current.push({ abortController, name: file.originFileObj.name });
      const config: AxiosRequestConfig = {
        onUploadProgress: (event) => {
          const percent = (event.loaded / event.total) * 100;
          setFileList((prev) => {
            const sliced = prev.slice();
            const currentFile = sliced.find((fileLoad) => fileLoad.originFileObj.name === file.originFileObj.name);
            if (!currentFile) return sliced.every((fileLoad) => fileLoad.status === "done") ? [] : sliced;
            currentFile.status = "uploading";
            currentFile.percent = percent;
            return sliced;
          });
        },
        signal: abortController.signal,
      };
      fmData.append("files", file.originFileObj as any, file.name);

      (isDebateChat ? postFileChat(chatId, fmData, config) : postFileSupportChat(chatId, fmData, config))
        .then((value) => {
          if (value) {
            setFileList((prev) => {
              const sliced = prev.slice();
              const currentFile = sliced.find((fileLoad) => fileLoad.originFileObj.name === file.originFileObj.name);
              if (!currentFile) return sliced.every((fileLoad) => fileLoad.status === "done") ? [] : sliced;
              currentFile.status = "done";
              return sliced.every((fileLoad) => fileLoad.status === "done") ? [] : sliced;
            });
          }
        })
        .catch(() => {
          setFileList((prev) => {
            const sliced = prev.slice();
            const currentFile = sliced.find((fileLoad) => fileLoad.originFileObj.name === file.originFileObj.name);
            if (!currentFile) return sliced.every((fileLoad) => fileLoad.status === "done") ? [] : sliced;
            currentFile.status = "error";
            return sliced;
          });
        });
    }
    setIsUploadingFile(true);
  };

  const sendTextHandler = () => {
    if (fileList.find((file) => file.status !== "error") && !isUploadingFile) {
      handleSendFile();
      return;
    }
    sendSocketMessage(textMessage, chatId);
    setTextMessage("");
  };

  const loadMoreData = () => {
    if (loading) return;
    loadMore(cursor);
  };

  useEffect(() => {
    if (chatId) {
      divRef.current?.scrollTo({ left: 0, top: 0 });
    }
  }, [chatId]);

  return (
    <div className={styles.chatWrapper}>
      <>
        <div className={styles.chat} id="scrollableElem" ref={divRef}>
          <InfiniteScroll
            dataLength={chatData.messages.length}
            hasMore={hasMore}
            className={styles.chat}
            loader={<Spin />}
            inverse={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            next={loadMoreData}
            scrollableTarget="scrollableElem"
          >
            {chatData.messages?.map((message) => {
              if (message.authorType === "CLIENT" || message.author) {
                return (
                  <div key={message.id} className={styles.myMessages}>
                    {message.author && (
                      <div
                        className={styles.messageDate}
                      >{`${message.author.firstName} ${message.author.lastName}`}</div>
                    )}
                    <div className={styles.messageDate}>{dayjs(message.createdAt).format("MMMM D, hh:mm A")}</div>
                    <div className={styles.message}>
                      {" "}
                      {message.contentType === "file" ? (
                        <a href={message.link} target="_blank" rel="noreferrer">
                          {message.content}
                        </a>
                      ) : (
                        message.content
                      )}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={message.id} className={styles.otherMessages}>
                    <div className={styles.messageDate}>{dayjs(message.createdAt).format("MMMM D, hh:mm A")}</div>
                    <div className={styles.message}>
                      {" "}
                      {message.contentType === "file" ? (
                        <a href={message.link} target="_blank" rel="noreferrer">
                          {message.content}
                        </a>
                      ) : (
                        message.content
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </InfiniteScroll>
        </div>

        <div className={styles.inputBox}>
          <UploadFilesComponent
            abortDataRef={abortDataRef}
            fileList={fileList}
            setFileList={setFileList}
            setIsUploading={setIsUploadingFile}
          >
            <Button type="link" className={styles.paperClipBtn} icon={<PaperClipIcon />} />
          </UploadFilesComponent>
          <Input
            className={styles.input}
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                sendTextHandler();
              }
            }}
          />
          <button className={styles.sendButton} onClick={sendTextHandler} />
        </div>
      </>
    </div>
  );
};
