import { useRequest } from "ahooks";
import { createContext } from "react";
import { getUsers } from "helpers/backend_helper";

type Wallet = { address: string };

export type User = {
  active: boolean;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
  updatedAt: string;
  id: string;
  userRoles: [
    {
      role: string;
      approved: boolean;
    },
  ];
  userWallets: Wallet[];
};

export type ContextProps = {
  loading: boolean;
  users: User[];
  getUsersList: (country: string) => void;
};

export const UsersContext = createContext({} as ContextProps);

export const UsersProvider = ({ children }: { children: JSX.Element }) => {
  const {
    loading,
    data: users,
    run: getUsersList,
  } = useRequest(getUsers, {
    manual: true,
    onError: (error) => {
      console.log(error);
    },
  });

  return <UsersContext.Provider value={{ loading, users, getUsersList }}>{children}</UsersContext.Provider>;
};
