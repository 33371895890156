import { DebateDetails } from "../Debate.types";
import { formatDate } from "../utils";

export const mapDebateDetails = (data: DebateDetails) => {
  if (!data) return {};

  const { product, chat, lessor, lessee, rent, ...debate } = structuredClone(data);

  [product, chat, lessor, lessee, rent, debate].forEach((i) => {
    Object.entries(i).forEach(([key, value]) => {
      if (value && (typeof value === "string" || typeof value === "number") && key !== "avatar") {
        if (["createdAt", "updatedAt", "dateStart", "dateEnd"].includes(key)) {
          i[key] = formatDate(value as string);
        }
      } else {
        delete i[key];
      }
    });
  });

  return { product, chat, lessor, lessee, rent, debate };
};

export const API_ORIGIN = process.env.REACT_APP_BACKEND_URL;

export const getProductPhotoUrl = (data: DebateDetails) =>
  data ? `${API_ORIGIN}/product/photo/${data.rent.product.id}/?photo=${data.rent.product.previewPhotos[0]}` : "";

const getAvatarUrl = (url: string) => `${API_ORIGIN}/public/avatars/${url}`;

export const getLesseePhotoUrl = (data: DebateDetails) => (data ? getAvatarUrl(data.lessee.avatar) : "");

export const getLessorPhotoUrl = (data: DebateDetails) => (data ? getAvatarUrl(data.lessor.avatar) : "");
