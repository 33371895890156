import { Button, Form, Modal, Popconfirm, Spin } from "antd";
import { useContext, useEffect } from "react";
import { useRequest } from "ahooks";
import { deleteCategory, getCategoryById, updateCategory } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import LanguageForm from "pages/Main/Components/LanguageForm";

import styles from "./index.module.css";

type Props = {
  isModalOpen: boolean;
  refresh: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
};

const EditCategoryModal = ({ isModalOpen, setIsModalOpen, id, refresh }: Props) => {
  const [form] = Form.useForm();
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const languages = localCountry?.languages;
  const { loading: deleteLoading, run: deleteCategoryHandler } = useRequest(deleteCategory, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "category was deleted");
      setIsModalOpen(false);
      refresh();
    },
  });
  const { loading: updateLoading, run: update } = useRequest(updateCategory, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "category was updated");
      setIsModalOpen(false);
      refresh();
    },
  });
  const {
    loading: getLoading,
    data: selectedNode,
    run,
  } = useRequest(() => getCategoryById(id.toString(), localCountry?.iso), {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const confirmDelete = () => {
    deleteCategoryHandler(selectedNode.id, localCountry.iso);
  };

  useEffect(() => {
    if (selectedNode) {
      selectedNode.translations?.map((el) => {
        form.setFieldValue(`name${el.languageCode}`, el.name);
        form.setFieldValue(`description${el.languageCode}`, el.description);
      });
    }
  }, [form, selectedNode]);

  useEffect(() => {
    if (id) {
      run();
    }
  }, [id, run]);

  if (!selectedNode) {
    return null;
  }

  return (
    <Modal
      className={styles.modal}
      title="Edit category"
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <Form
        layout="vertical"
        form={form}
        className={styles.form}
        initialValues={selectedNode}
        onFinish={(values) => {
          const categories = languages?.map((language) => {
            const nameKey = `name${language.code}`;
            const descriptionKey = `description${language.code}`;
            return { name: values[nameKey], description: values[descriptionKey], languageCode: language.code };
          });
          update(categories, localCountry?.iso, selectedNode.id);
        }}
      >
        {getLoading ? (
          <div className={styles.spin}>
            <Spin />
          </div>
        ) : (
          <>
            {languages?.map((language) => <LanguageForm key={language?.id} language={language} />)}
            <div className={styles.buttonsWrapper}>
              <Button style={{ marginRight: 20 }} type="primary" htmlType="submit" loading={updateLoading}>
                Update
              </Button>
              <Popconfirm
                title={`Delete ${selectedNode.translations[0].name}`}
                description="Are you sure to delete this category?"
                onConfirm={confirmDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger loading={deleteLoading}>
                  Delete
                </Button>
              </Popconfirm>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EditCategoryModal;
