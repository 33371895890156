import { Form, Input } from "antd";

type Props = {
  language: {
    id: string;
    code: string;
    name: string;
  };
  name?: string;
  list?: string;
};

const LanguageForm = ({ language, list, name = "name" }: Props) => {
  return (
    <Form.Item
      name={list ? [list, `${name}${language.code}`] : `${name}${language.code}`}
      rules={[
        {
          required: true,
          message: `${name} is required`,
        },
      ]}
    >
      <Input type="text" placeholder={`${name} ${language.code}`} />
    </Form.Item>
  );
};

export default LanguageForm;
