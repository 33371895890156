import { useCallback, useContext, useMemo, useState } from "react";
import { Button, Divider, Popconfirm, Table, Tag } from "antd";
import { useRequest } from "ahooks";
import {
  deleteGroup,
  deleteGroupFromCategory,
  deleteOption,
  getCategories,
  getGroupOptions,
  getGroups,
} from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import NewGroup from "./NewGroup";
import EditGroupModal from "./EditModal";
import { getTreeItems, type Category } from "../Categories";
import { AdminContext } from "context/adminContext";
import AddCategoryModal from "./AddCategory";

import styles from "./index.module.css";
import EditOptionModal from "../Options/EditModal";
import NewOption from "../Options/NewOption";

export type Group = {
  name: string;
  id: number;
  description: string;
  productCategories: Category[];
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: <div className={styles.renter}>Name</div>,
    dataIndex: "name",
    key: "name",
  },
  {
    title: <div className={styles.period}>Description</div>,
    dataIndex: "description",
    key: "description",
  },
  {
    title: <div className={styles.price}>Categories</div>,
    dataIndex: "categories",
    key: "categories",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];

const columnsOptions = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Order",
    dataIndex: "order",
    key: "order",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];

const Groups = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group>(null);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const languages = localCountry?.languages;
  const [isEditModalOptionsOpen, setIsEditModalOptionsOpen] = useState(false);
  const [loadedOptions, setLoadedOptions] = useState<{ id: number; value: any }[]>([]);
  const [editData, setEditData] = useState<{ optionId: number; groupId: number }>();
  const {
    loading: getLoading,
    data,
    refresh,
  } = useRequest(() => getGroups(localCountry?.iso), {
    ready: !!localCountry,
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });
  const { loading: getCategoriesLoading, data: categories } = useRequest(() => getCategories(localCountry?.iso), {
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const { run: deleteHandler } = useRequest(
    (id: string, localCountry: string, groupId: number) => {
      const deleted = deleteOption(id, localCountry);
      deleted.then(() => {
        getOptions(groupId);
      });
      return deleted;
    },
    {
      manual: true,
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
      },
      onSuccess: () => {
        openNotificationWithIcon("success", "option was deleted");
      },
    },
  );

  const { run: getOptions } = useRequest((id: number) => getGroupOptions(localCountry?.iso, id.toString()), {
    ready: !!localCountry,
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: (data, params) => {
      setLoadedOptions((prev) => {
        const sliced = prev.slice();
        const existsOptionsOfGroup = sliced.find((value) => params[0] === value.id);

        if (existsOptionsOfGroup) {
          existsOptionsOfGroup.value = data.options.map((el) => createOptionRow(el, params[0]));
        } else {
          sliced.push({
            id: params[0],
            value: data.options.map((el) => createOptionRow(el, params[0])),
          });
        }
        return sliced;
      });
    },
  });

  const createOptionRow = useCallback(
    (el, groupId: number) => {
      return {
        ...el,
        name: <div>{el.name}</div>,
        key: el.id,
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setEditData({ groupId, optionId: el.id });
                setIsEditModalOptionsOpen(true);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title={`Delete ${el.name}`}
              description="Are you sure to delete this option?"
              onConfirm={() => {
                deleteHandler(el.id, localCountry.iso, groupId);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </div>
        ),
      };
    },
    [deleteHandler, localCountry.iso, setEditData],
  );

  const { loading: deleteLoading, run: deleteGroupHandler } = useRequest(deleteGroup, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "group was deleted");
      refresh();
    },
  });
  const { run: deleteFromCategory } = useRequest(deleteGroupFromCategory, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "group was deleted from category");
      refresh();
    },
  });
  const treeData = useMemo(() => (!categories ? [] : categories?.map((el) => getTreeItems(el))), [categories]);
  const source = useMemo(
    () =>
      data?.map((el, i) => ({
        ...el,
        name: el.name,
        key: i,
        categories: (
          <div>
            {el.productCategories?.map((pc, i) => (
              <Tag
                closable
                color="red"
                key={pc.name + i}
                onClose={() => deleteFromCategory(el.id, pc.id, localCountry?.iso)}
              >
                {pc.name}
              </Tag>
            ))}
          </div>
        ),
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setSelectedGroup(el);
                setIsModalOpen(true);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title={`Delete ${el.name}`}
              description="Are you sure to delete this group?"
              onConfirm={() => deleteGroupHandler(el.id, localCountry.iso)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger loading={deleteLoading}>
                Delete
              </Button>
            </Popconfirm>
            <Button
              style={{ marginLeft: 20 }}
              type="primary"
              onClick={() => {
                setSelectedGroup(el);
                setIsAddModalOpen(true);
              }}
            >
              Add category
            </Button>
          </div>
        ),
      })) || [],
    [data, deleteGroupHandler, deleteFromCategory, deleteLoading, localCountry],
  );

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        <div className={styles.titleWrapper}>
          <span>Groups</span>
          <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
            Add new group
          </Button>
        </div>
      </Divider>
      <Table
        loading={getLoading}
        dataSource={source}
        columns={columns}
        className={styles.table}
        pagination={false}
        expandable={{
          onExpand: (expanded, record) => {
            if (expanded) {
              if (loadedOptions?.find((value) => value.id === record.id)) return;
              getOptions(record.id);
            }
          },
          expandedRowRender: (record) => {
            return (
              <>
                <Table
                  columns={columnsOptions}
                  dataSource={loadedOptions?.find((value) => value.id === record.id)?.value}
                  pagination={false}
                  className={styles.tableOptions}
                />
                <div style={{ marginLeft: "103px", marginTop: "10px" }}>
                  <NewOption
                    groups={data}
                    refresh={() => {
                      getOptions(record.id);
                    }}
                    languages={languages}
                    selectedDefaultGroupValue={record.id}
                  />
                </div>
              </>
            );
          },
        }}
      />
      <div className={styles.buttonContainer}>
        <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
          Add new group
        </Button>
      </div>
      <EditGroupModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refresh={refresh}
        id={selectedGroup?.id}
      />
      <AddCategoryModal
        isModalOpen={isAddModalOpen}
        setIsModalOpen={setIsAddModalOpen}
        refresh={refresh}
        id={selectedGroup?.id}
        getCategoriesLoading={getCategoriesLoading}
        treeData={treeData}
      />
      <NewGroup
        refresh={refresh}
        treeData={treeData}
        getCategoriesLoading={getCategoriesLoading}
        setIsCreateModalOpen={setIsCreateModalOpen}
        isCreateModalOpen={isCreateModalOpen}
      />
      <EditOptionModal
        languages={languages}
        isModalOpen={isEditModalOptionsOpen}
        setIsModalOpen={setIsEditModalOptionsOpen}
        refresh={() => {
          getOptions(editData?.groupId);
        }}
        optionId={editData?.optionId}
      />
    </div>
  );
};

export default Groups;
