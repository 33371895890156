import { useContext } from "react";
import { Button, Form, Modal, TreeSelect } from "antd";
import { useRequest } from "ahooks";
import { createCategory } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";

import styles from "./index.module.css";
import { AdminContext } from "context/adminContext";
import LanguageForm from "pages/Main/Components/LanguageForm";

type Props = {
  refresh: () => void;
  items: any[];
  getLoading: boolean;
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewCategory = ({ refresh, items, getLoading, isCreateModalOpen, setIsCreateModalOpen }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const languages = localCountry?.languages;
  const { loading: createLoading, run: createCat } = useRequest(createCategory, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "category was created");
      refresh();
      setIsCreateModalOpen(false);
    },
  });
  console.log(languages);
  return (
    <Modal
      className={styles.modal}
      title="Create new category"
      open={isCreateModalOpen}
      footer={null}
      onCancel={() => setIsCreateModalOpen(false)}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={(values) => {
            const categories = languages?.map((language) => {
              const nameKey = `name${language.code}`;
              const descriptionKey = `description${language.code}`;
              return { name: values[nameKey], description: values[descriptionKey], languageCode: language.code };
            });
            createCat(categories, localCountry?.iso, +values.parentId);
          }}
          style={{ maxWidth: "600px" }}
        >
          {languages?.map((language) => <LanguageForm key={language.id} language={language} />)}
          <Form.Item name="parentId" label="Parent">
            <TreeSelect loading={getLoading} style={{ width: "100%" }} treeData={items} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={createLoading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewCategory;
