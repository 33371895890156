import { useRequest } from "ahooks";
import { createContext, useContext, useEffect, useState } from "react";
import { getChats } from "helpers/backend_helper";
import { NotificationContext } from "./notificationContext";
import { AdminContext } from "./adminContext";
import { SocketContext } from "./socketContext";
import { STATUSES } from "pages/Main/Components/AdminPanel/Chats";

export type Chat = {
  client: {
    avatar: string | null;
    cityId: number;
    description: string | null;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    patronymic: string | null;
  };
  clientId: string;
  createdAt: string;
  id: string;
  lastMessage: any[];
  status: string;
  support: string;
  unreadMessages: number;
  updatedAt: string;
  partnerOnline: boolean;
};

export type Chats = {
  count: number;
  chats: Chat[];
};

export type ContextProps = {
  chats: Chats;
  loading: boolean;
  setChats: React.Dispatch<Chats>;
  getUsersByStatus: (statuses: string[]) => void;
};

export const ChatsContext = createContext({} as ContextProps);

export const ChatsProvider = ({ children }: { children: JSX.Element }) => {
  const [chats, setChats] = useState<Chats | null>(null);
  const { localCountry, isAuthorized, accountData } = useContext(AdminContext);
  const { subscribeToUserOnliness, online } = useContext(SocketContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);

  useEffect(() => {
    if (online.clientId && chats?.chats.length) {
      const chat = chats?.chats.find((ch) => ch.clientId === online.clientId);
      chat.partnerOnline = online.online;
      setChats((prev) => {
        const sliced = prev.chats.slice();
        return { ...prev, chats: sliced };
      });
    }
  }, [online]);

  const { loading, run: getUsersByStatus } = useRequest((statuses: string[]) => getChats(localCountry?.iso, statuses), {
    ready: !!localCountry && isAuthorized,
    refreshDeps: [JSON.stringify(localCountry)],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: (data) => {
      if (data) {
        data.chats?.map((chat) => {
          subscribeToUserOnliness(chat.clientId);
        });
        const accessableChats = data.chats.filter((chat) => {
          if (chat.status === STATUSES.IN_PROGRESS) return chat.support === accountData.id;
          return true;
        });
        const chatsData = {
          count: accessableChats.length,
          chats: accessableChats,
        };
        chatsData.chats = chatsData.chats.map((el) => ({ ...el, online: false }));
        setChats(chatsData);
      }
    },
  });

  return (
    <ChatsContext.Provider
      value={{
        chats,
        loading,
        setChats,
        getUsersByStatus,
      }}
    >
      {children}
    </ChatsContext.Provider>
  );
};
