import { Modal, Spin, Avatar } from "antd";
import { useMemo, ReactNode, JSX, useContext } from "react";
import { Actions } from "../Actions";
import { getLesseePhotoUrl, getLessorPhotoUrl, getProductPhotoUrl, mapDebateDetails } from "./utils";
import { DebateDetails } from "../Debate.types";
import { AdminContext } from "context/adminContext";

import styles from "./Details.module.css";
import { DetailsModalChat } from "./DetailsModalChat";

interface IDetailsModalProps {
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  isOpened: boolean;
  data: DebateDetails;
  refreshData: () => void;
}

const DetailsSection = ({
  data,
  title,
  photo = null,
}: {
  data: Record<string, string | number>;
  title: string;
  photo?: ReactNode | JSX.Element;
}) =>
  data ? (
    <div className={styles.col}>
      <h3 className={styles.itemHeader}>{title}</h3>
      {photo}
      {Object.entries(data).map(([key, value]) => (
        <div key={key} className={styles.itemContainer}>
          <div className={styles.itemHeader}>{key}:</div>
          <div>{value as string}</div>
        </div>
      ))}
    </div>
  ) : null;

export const DetailsModal = ({ isOpened, setIsOpened, loading, data, refreshData }: IDetailsModalProps) => {
  const { chat, lessor, lessee, product, rent, debate } = useMemo(() => mapDebateDetails(data), [data]);
  const productPhotoUrl = useMemo(() => getProductPhotoUrl(data), [data]);
  const { accountData } = useContext(AdminContext);

  const showChats = useMemo(
    () => isOpened && data && accountData?.id === data?.arbitr && data?.status === "IN_PROGRESS",
    [data, accountData, isOpened],
  );

  return (
    <Modal
      open={isOpened}
      footer={null}
      onCancel={() => setIsOpened(false)}
      className={styles.debateDetailModal}
      destroyOnClose
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          <div className={styles.detailsWrapper}>
            <div className={styles.dataContainer}>
              <DetailsSection data={debate} title="Debate info" />
              <DetailsSection data={rent} title="Rent info" />
              <DetailsSection
                data={lessor}
                title="Lessor info"
                photo={<Avatar size={120} shape="square" src={getLessorPhotoUrl(data)} rootClassName={styles.photo} />}
              />
              <DetailsSection
                data={lessee}
                title="Lessee info"
                photo={<Avatar size={120} shape="square" src={getLesseePhotoUrl(data)} rootClassName={styles.photo} />}
              />
              <DetailsSection
                data={product}
                title="Product info"
                photo={<img src={productPhotoUrl} width={150} height={150} className={styles.photo} />}
              />
            </div>
            {showChats && <DetailsModalChat chatId={chat.id} />}
          </div>
          <Actions data={data} refreshData={refreshData} setIsOpened={setIsOpened} />
        </>
      )}
    </Modal>
  );
};
