import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import styles from "./index.module.css";

const CarouselItem = ({ photos }) => {
  return (
    <div className={styles.wrapper}>
      <Carousel className={styles.carousel} arrows prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
        {photos.map((ph) => (
          <div key={ph.preview} className={styles.imageContainer}>
            <img src={ph.preview} alt="carousel" className={styles.image} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselItem;
