import { UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Divider, Popconfirm, Spin } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Chat from "./Chat";

import styles from "./index.module.css";
import { ChatsContext } from "context/chatsContext";

export enum STATUSES {
  WAIT = "WAIT",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

const MainMessagesContent = () => {
  const { loading, chats, getUsersByStatus } = useContext(ChatsContext);
  const [currentChat, setCurrentChat] = useState(null);
  const selectedStatus = useRef<STATUSES>(STATUSES.IN_PROGRESS);
  const changeStatus = useCallback(
    (status: STATUSES) => {
      getUsersByStatus([status]);
      setCurrentChat({});
      selectedStatus.current = status;
    },
    [chats],
  );
  useEffect(() => {
    getUsersByStatus([STATUSES.IN_PROGRESS]);
  }, []);

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Messages
      </Divider>
      <div className={styles.buttonStatusWrapper}>
        <h3>User by statuses</h3>
        <Button
          type="primary"
          style={selectedStatus.current === STATUSES.WAIT ? { boxShadow: "0 0 10px #ffcc4a" } : {}}
          onClick={() => changeStatus(STATUSES.WAIT)}
        >
          WAIT
        </Button>
        <Button
          type="primary"
          style={
            selectedStatus.current === STATUSES.IN_PROGRESS
              ? { backgroundColor: "yellowgreen", boxShadow: "0 0 10px yellowgreen" }
              : { backgroundColor: "yellowgreen" }
          }
          onClick={() => changeStatus(STATUSES.IN_PROGRESS)}
        >
          IN PROGRESS
        </Button>
        <Button
          type="primary"
          style={
            selectedStatus.current === STATUSES.FINISHED
              ? { backgroundColor: "#2986cc", boxShadow: "0 0 10px #2986cc" }
              : { backgroundColor: "#2986cc" }
          }
          onClick={() => changeStatus(STATUSES.FINISHED)}
        >
          FINISHED
        </Button>
      </div>
      {loading ? (
        <Spin style={{ position: "absolute", top: "50%", right: "50%", transform: "translate(50%, -50%)" }}></Spin>
      ) : (
        <div className={styles.messenger}>
          <div className={styles.connections}>
            {chats?.chats.map((chat) => (
              <Popconfirm
                key={chat.id}
                title="Start The Chat?"
                onConfirm={() => {
                  localStorage.setItem("prevChatIdAdmin", chat.id);
                  setCurrentChat(chat);
                }}
                disabled={selectedStatus.current === STATUSES.IN_PROGRESS}
              >
                <Button
                  className={
                    currentChat?.id === chat.id ? `${styles.connectButton} ${styles.acrive}` : styles.connectButton
                  }
                  onClick={() => {
                    if (selectedStatus.current != STATUSES.IN_PROGRESS) return;
                    localStorage.setItem("prevChatIdAdmin", chat.id);
                    setCurrentChat(chat);
                  }}
                >
                  {chat.client?.avatar ? (
                    <img
                      className={styles.picture}
                      src={`https://c2c-api.goldapp.ru/public/avatars/${chat.client.avatar}`}
                      width={46}
                      height={46}
                    />
                  ) : (
                    <Avatar size={46} icon={<UserOutlined />} />
                  )}
                  <div className={styles.textWrapper}>
                    <div className={styles.textTitle}>
                      {`${chat.client?.firstName} ${chat.client?.lastName}`}
                      {chat.partnerOnline ? <Badge status="success" style={{ marginLeft: "8px" }} /> : null}
                    </div>
                    <div className={styles.supportText}>{`${
                      chat.lastMessage?.length ? chat.lastMessage[0].content.slice(0, 20) : ""
                    }...`}</div>
                  </div>
                  {chat.unreadMessages ? (
                    <div className={styles.pictureWrapper}>
                      <Badge status="warning" />
                    </div>
                  ) : null}
                </Button>
              </Popconfirm>
            ))}
          </div>
          <Chat
            id={currentChat?.id}
            currentStatusChat={selectedStatus.current}
            closeChat={() => {
              setCurrentChat({});
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MainMessagesContent;
