import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Spin } from "antd";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Suspense
      fallback={
        <div
          style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <Spin />
        </div>
      }
    >
      <App />
    </Suspense>
  </BrowserRouter>,
);
