import { useContext, useEffect, useRef, useState } from "react";
import { Button, Divider, Form, Input, Modal, Popconfirm } from "antd";
import { useRequest } from "ahooks";
import { createGeoZone, deleteGeoZone, getCityWithGeozones } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Props = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  selectedCity: any;
};

const createBoundsString = (northeast, southwest) => {
  return `${southwest.lat},${southwest.lng}|${northeast.lat},${northeast.lng}`;
};

const EditGeoModal = ({ isModalOpen, setIsModalOpen, selectedCity }: Props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const searchInputRef = useRef(null);

  const boundsString = selectedCity ? createBoundsString(selectedCity?.northeast, selectedCity?.southwest) : "";
  const { data: geoData, refresh } = useRequest(() => getCityWithGeozones(localCountry.iso, selectedCity.id), {
    ready: !!selectedCity?.id && !!localCountry.iso,
    refreshDeps: [selectedCity?.id],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });
  const { loading: createGeoLoading, run: createGeo } = useRequest(
    () => createGeoZone(localCountry.iso, selectedCity.id, data),
    {
      manual: true,
      onError: (error: any) => {
        openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
      },
      onSuccess: () => {
        searchInputRef.current.value = "";
        openNotificationWithIcon("success", "geozone was created");
        refresh();
      },
    },
  );
  const { loading: deleteGeoLoading, run: deleteGeo } = useRequest((id) => deleteGeoZone(localCountry.iso, id), {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "geozone was deleted");
      refresh();
    },
  });

  useEffect(() => {
    if (localCountry?.iso && boundsString) {
      if ((window as any).google && searchInputRef.current) {
        const autocomplete = new (window as any).google.maps.places.Autocomplete(searchInputRef.current, {
          types: ["(regions)"],
          componentRestrictions: { country: [localCountry.iso] },
          bounds: boundsString,
        });
        autocomplete.addListener("place_changed", async () => {
          const place = autocomplete.getPlace();
          const data = {
            address: place.formatted_address,
            results: [
              { address_components: [place.address_components[0]], place_id: place.place_id, types: place.types },
            ],
          };
          setData(data);
        });
      }
    }
  }, [localCountry?.iso, boundsString]);

  if (!selectedCity) return null;

  return (
    <Modal
      className={styles.modal}
      style={{ top: "30px" }}
      title={`Update ${selectedCity.address} geozones`}
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <div className={styles.container}>
        <Form form={form} className={styles.inputWrapper}>
          <Divider />
          <h3 className={styles.header}>Geozones</h3>
          {geoData?.geozone.map((geo) => (
            <div className={styles.geoWrapper} key={geo.id}>
              <Input value={geo.address} style={{ width: 300 }} />
              <Popconfirm
                title={`Delete ${geo.address}`}
                description="Are you sure to delete this geozone?"
                onConfirm={() => deleteGeo(geo.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger loading={deleteGeoLoading}>
                  Delete
                </Button>
              </Popconfirm>
            </div>
          ))}
          <Divider />
          <h3 className={styles.header}>Add new geozone</h3>
          <div className={styles.geoWrapper}>
            <input className={styles.input} ref={searchInputRef} placeholder="Select region" style={{ width: 300 }} />
            <Button disabled={!selectedCity.id} type="primary" loading={createGeoLoading} onClick={() => createGeo()}>
              Create
            </Button>
          </div>
          <Form.Item>
            <Button disabled={!selectedCity.id} type="primary" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditGeoModal;
