export class TonStorage {
  store: any;

  constructor(value: Object | null) {
    this.store = value ?? {};
  }

  async getItem(key: string): Promise<string | null> {
    return this.store[key];
  }

  async setItem(key: string, value: string): Promise<void> {
    this.store[key] = value;
  }

  async removeItem(key: string): Promise<void> {
    delete this.store[key];
  }

  export(): any {
    return this.store;
  }
}
