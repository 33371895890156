import { LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useContext } from "react";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

const Logout = () => {
  const { setIsAuthorized } = useContext(AdminContext);

  const onClick = () => {
    setIsAuthorized(false);
    localStorage.removeItem("accessToken");
    sessionStorage.removeItem("accessToken");
  };

  return (
    <Button type="text" className={styles.button} icon={<LogoutOutlined />} onClick={onClick}>
      Logout
    </Button>
  );
};

export default Logout;
