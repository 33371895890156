import { SocketContext } from "context/socketContext";
import { useContext, useEffect, useState } from "react";
import { useRequest } from "ahooks";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { endSupportChat, getPossibleChangeSupports, postChangeSupport, startSupportChat } from "helpers/backend_helper";

import { Chat } from "pages/Main/Components/Chat";
import { Button, Dropdown, Popconfirm } from "antd";
import styles from "./index.module.css";
import { ChatsContext } from "context/chatsContext";
import { STATUSES } from "..";

type Props = {
  id: string;
  currentStatusChat: STATUSES;
  closeChat: () => void;
};

const AdminChat = ({ id, closeChat, currentStatusChat }: Props) => {
  const [localId, setLocalId] = useState(null);
  const { joinChatAdmin, sendMessageSupport, leaveChatAdmin } = useContext(SocketContext);
  const { localCountry, getChatHistory, getHistoryLoading, chats, hasMore, accountData } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [supports, setSupports] = useState();
  const { getUsersByStatus } = useContext(ChatsContext);
  const { loading } = useRequest(() => startSupportChat(localCountry?.iso, id), {
    ready: !!id,
    refreshDeps: [id],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      joinChatAdmin(id);
      getChatHistory(id, 0);
    },
  });

  useRequest(() => getPossibleChangeSupports(id, localCountry.iso), {
    refreshDeps: [id],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: (data) => {
      if (data.length) {
        const supportList = data
          .filter((sup) => sup.id !== accountData.id)
          .map((sup) => {
            return {
              key: sup.id,
              label: (
                <Popconfirm
                  key={sup.id}
                  title="Transfer chat?"
                  onConfirm={() => {
                    postChangeSupport(id, sup.id, localCountry.iso).then(() => {
                      closeChat();
                      getUsersByStatus([currentStatusChat]);
                      openNotificationWithIcon("success", "User successfully transfered to another support");
                    });
                  }}
                >
                  <Button type="primary" ghost style={{ color: "#000" }}>
                    <div>
                      <span>{sup.firstName} </span>
                      <span>{sup.lastName}</span>
                    </div>
                  </Button>
                </Popconfirm>
              ),
            };
          });
        setSupports(supportList);
      }
      console.log(data);
    },
  });

  useEffect(() => {
    if (id) {
      hasMore.current = false;

      if (localId) {
        leaveChatAdmin(id);
      }

      setLocalId(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      console.log("delete");
      leaveChatAdmin(id);
    };
  }, []);

  return (
    <>
      {id ? (
        <Chat
          cursor={chats.supportChat.cursor}
          loadMore={(cursor) => getChatHistory(id, cursor)}
          chatId={id}
          hasMore={hasMore.current}
          sendSocketMessage={sendMessageSupport}
          loading={loading || getHistoryLoading}
          chatData={chats?.supportChat}
          isDebateChat={false}
        />
      ) : null}
      <Popconfirm
        title="Close the request?"
        onConfirm={() => {
          endSupportChat(localCountry?.iso, id).then(() => {
            openNotificationWithIcon("success", "User's request is closed");
            closeChat();
            getUsersByStatus([currentStatusChat]);
          });
        }}
      >
        <Button
          type="primary"
          className={styles.closeButtom}
          disabled={id && currentStatusChat !== "FINISHED" ? false : true}
        >
          Close request
        </Button>
      </Popconfirm>
      <Dropdown
        className={styles.transferChatButton}
        trigger={["click"]}
        disabled={supports ? false : true}
        menu={{
          items: supports ? supports : [],
        }}
      >
        <Button>Transfer chat</Button>
      </Dropdown>
    </>
  );
};

export default AdminChat;
