import { useContext, useEffect } from "react";
import { Button, Form, Input, Modal, Select, Switch } from "antd";
import { useRequest } from "ahooks";
import { updateCountry } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { Country } from "../..";
import { Language } from "../../../Languages";

import styles from "./index.module.css";
import { AdminContext } from "context/adminContext";

type Props = {
  refresh: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  selectedCountry: Country;
  languages: Language[];
};

const EditCountryModal = ({ refresh, isModalOpen, setIsModalOpen, selectedCountry, languages }: Props) => {
  const [form] = Form.useForm();
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { refreshCountries } = useContext(AdminContext);

  const { loading: updateLoading, run: update } = useRequest(updateCountry, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "country was updated");
      refresh();
      setIsModalOpen(false);
      refreshCountries();
    },
  });

  const languageSelect = languages?.map((l) => ({ label: l.name, value: l.id }));

  useEffect(() => {
    if (selectedCountry) {
      form.setFieldValue("name", selectedCountry.name);
      form.setFieldValue("active", selectedCountry.active);
      form.setFieldValue("default", selectedCountry.defaultLanguageId);
      form.setFieldValue("languages", selectedCountry?.languages?.map((el) => el.id));
    }
  }, [form, selectedCountry]);

  if (!selectedCountry) return null;

  return (
    <Modal
      className={styles.modal}
      title="Update country"
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <div className={styles.container}>
        <Form
          form={form}
          className={styles.inputWrapper}
          onFinish={(values) =>
            update(selectedCountry.id, values.active, values.name, values?.languages, values.default)
          }
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "name is required",
              },
            ]}
          >
            <Input type="text" placeholder="name" value={selectedCountry.name} />
          </Form.Item>
          <Form.Item name="default" label="Default language">
            <Select placeholder="languages" options={languageSelect} />
          </Form.Item>
          <Form.Item
            name="languages"
            label="Languages"
            rules={[
              {
                required: true,
                message: "Language is required",
              },
            ]}
          >
            <Select mode="multiple" placeholder="languages" options={languageSelect} />
          </Form.Item>
          <Form.Item name="active">
            <Switch checkedChildren="active" unCheckedChildren="inactive" />
          </Form.Item>
          <Form.Item>
            <Button disabled={!selectedCountry.id} type="primary" htmlType="submit" loading={updateLoading}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditCountryModal;
