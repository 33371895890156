import { useContext } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useRequest } from "ahooks";
import { createLanguage } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Props = {
  refresh: () => void;
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateModalOpen: boolean;
};

const NewLanguageModal = ({ refresh, isCreateModalOpen, setIsCreateModalOpen }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const { loading: createLoading, run: create } = useRequest(createLanguage, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "language was created");
      refresh();
      setIsCreateModalOpen(false);
    },
  });

  return (
    <>
      <Modal
        className={styles.modal}
        title="Create new language"
        open={isCreateModalOpen}
        footer={null}
        onCancel={() => setIsCreateModalOpen(false)}
      >
        <div className={styles.container}>
          <Form
            className={styles.inputWrapper}
            onFinish={(values: any) => create(values.code, values.name, localCountry.iso)}
          >
            <Form.Item
              name="code"
              label="Code"
              rules={[
                {
                  required: true,
                  message: "code is required",
                },
              ]}
            >
              <Input type="text" placeholder="code" name="code" />
            </Form.Item>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "name is required",
                },
              ]}
            >
              <Input type="text" placeholder="name" name="name" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={createLoading} htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
        Add new language
      </Button>
    </>
  );
};

export default NewLanguageModal;
