import { useContext, useMemo, useState } from "react";
import { Button, Divider, Popconfirm, Skeleton, Table } from "antd";
import { useRequest } from "ahooks";
import { deleteLanguage, getLanguages } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import type { Country } from "../Countries";
import NewLanguageModal from "./NewModal";
import EditLanguageModal from "./EditModal";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

export type Language = {
  id: string;
  code: string;
  name: string;
  Countries: Partial<Country>[];
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Countries",
    dataIndex: "email",
    key: "email",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];

const Languages = () => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { refreshCountries, localCountry } = useContext(AdminContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { loading: deleteLoading, run: deleteLanguageHandler } = useRequest(deleteLanguage, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "language was deleted");
      refresh();
    },
  });
  const {
    loading,
    data,
    refresh: refreshLanguages,
  } = useRequest(getLanguages, {
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });
  const refresh = () => {
    refreshLanguages();
    refreshCountries();
  };
  const source = useMemo(
    () =>
      data?.map((el, i) => ({
        ...el,
        key: i,
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setSelectedLanguage(el);
                setIsModalOpen(true);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title={`Delete ${el.name}`}
              description="Are you sure to delete this language?"
              onConfirm={() => deleteLanguageHandler(el.id, localCountry.iso)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger loading={deleteLoading}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        ),
      })) || [],
    [data, deleteLanguageHandler, deleteLoading],
  );

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Languages
      </Divider>
      {loading ? (
        <Skeleton />
      ) : (
        <Table loading={loading} dataSource={source} columns={columns} className={styles.table} pagination={false} />
      )}
      <div className={styles.buttonContainer}>
        <NewLanguageModal
          refresh={refresh}
          isCreateModalOpen={isCreateModalOpen}
          setIsCreateModalOpen={setIsCreateModalOpen}
        />
        <EditLanguageModal
          refresh={refresh}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </div>
  );
};

export default Languages;
