import { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "antd";
import { useRequest } from "ahooks";
import { createCity } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Props = {
  refresh: () => void;
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateModalOpen: boolean;
};

const NewCityModal = ({ refresh, isCreateModalOpen, setIsCreateModalOpen }: Props) => {
  const [data, setData] = useState(null);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const searchInputRef = useRef(null);

  const { loading: createLoading, run: create } = useRequest(() => createCity(localCountry?.iso, data), {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "city was created");
      refresh();
      searchInputRef.current.value = "";
      setIsCreateModalOpen(false);
    },
  });

  useEffect(() => {
    if (localCountry?.iso && isCreateModalOpen) {
      if ((window as any).google && searchInputRef.current) {
        const autocomplete = new (window as any).google.maps.places.Autocomplete(searchInputRef.current, {
          types: ["(cities)"],
          componentRestrictions: { country: [localCountry.iso] },
          language: "en",
        });
        autocomplete.addListener("place_changed", async () => {
          const place = autocomplete.getPlace();
          const geocoder = new (window as any).google.maps.Geocoder();
          geocoder.geocode({ address: place.name, language: "en" }, (result) => {
            const bounds = result[0]?.geometry.bounds;
            const data = {
              placeId: place.place_id,
              localityShortName: result[0]?.address_components[0]?.short_name,
              address: place.formatted_address,
              bounds: {
                northeast: {
                  lat: bounds.getNorthEast().lat(),
                  lng: bounds.getNorthEast().lng(),
                },
                southwest: {
                  lat: bounds.getSouthWest().lat(),
                  lng: bounds.getSouthWest().lng(),
                },
              },
              location: {
                lat: result[0]?.geometry.location.lat(),
                lng: result[0]?.geometry.location.lng(),
              },
              deliveryPrice: 0,
            };
            setData(data);
          });
        });
      }
    }
  }, [localCountry?.iso, isCreateModalOpen]);

  return (
    <Modal
      className={styles.modal}
      title="Create new city"
      open={isCreateModalOpen}
      footer={null}
      onCancel={() => setIsCreateModalOpen(false)}
    >
      <div className={styles.container}>
        <Form className={styles.inputWrapper} onFinish={create}>
          <input className={styles.input} ref={searchInputRef} placeholder="Choose city" style={{ width: 200 }} />

          <Form.Item style={{ marginTop: "20px" }}>
            <Button type="primary" loading={createLoading} htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewCityModal;
