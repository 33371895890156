import { useContext, useEffect, useState } from "react";
import { Button, Divider, Form, Input, Skeleton, Tree, TreeDataNode } from "antd";
import { useRequest } from "ahooks";
import { getCategories, updateLevelCategory, updateOrderCategory } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import TextArea from "antd/es/input/TextArea";
import NewCategory from "./NewCategory";
import EditCategoryModal from "./EditModal";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

export type Category = {
  id: number;
  name: string;
  description: string;
  parent_id: null | number;
  root_parent_id: null | number;
  order: number;
  languageCode: string;
  children: Category[];
};

export type TreeData = Partial<Category> & {
  value: number;
  key: number;
  title: string;
};

export const initialCategory = { id: null, name: "", description: "", level: null, order: null };

export const getTreeItems = (category: Category) => {
  if (!category.children.length)
    return {
      value: category.id,
      id: category.id,
      order: category.order,
      parent_id: category.parent_id,
      language: category.languageCode,
      key: category.id,
      title: category.name,
      description: category.description,
      children: [],
    };
  const children = category.children?.map((el) => getTreeItems(el));
  return {
    value: category.id,
    id: category.id,
    parent_id: category.parent_id,
    order: category.order,
    key: category.id,
    title: category.name,
    language: category.languageCode,
    description: category.description,
    children,
  };
};

export const findId = (categories: Category[], id: string) => {
  let result;
  categories?.map((c) => {
    if (+c.id === +id) {
      result = c;
      return;
    }
    if (c.children.length) {
      const data = findId(c.children, id);
      if (data) {
        result = data;
        return;
      }
    }
  });

  return result;
};

const Categories = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { localCountry } = useContext(AdminContext);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [form] = Form.useForm();
  const [gData, setGData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const {
    loading: getLoading,
    data,
    refresh,
  } = useRequest(() => getCategories(localCountry?.iso), {
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const { run: updateLevel } = useRequest(updateLevelCategory, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "level was updated");
    },
  });
  const { run: updateOrder } = useRequest(updateOrderCategory, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "order was updated");
    },
  });

  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    if (info.dropToGap) {
      if (+info.node.parent_id !== +info.dragNode.parent_id) {
        updateLevel(info.dragNode.value, info.node.parent_id, localCountry.iso);
      } else {
        updateOrder(info.dragNode.value, info.dropPosition + 1, localCountry.iso);
      }
    } else {
      if (+info.dragNode.parent_id !== +info.node.value) {
        updateLevel(info.dragNode.value, info.node.value, localCountry.iso);
      } else {
        updateOrder(info.dragNode.value, info.dropPosition + 1, localCountry.iso);
      }
    }
    const loop = (
      data: TreeDataNode[],
      key: React.Key,
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void,
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };
    const data = [...gData];

    // Find dragObject
    let dragObj: TreeDataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else {
      let ar: TreeDataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i!, 0, dragObj!);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setGData(data);
  };

  const onSelect = (data) => {
    setSelectedNode(data);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (data) {
      const treeData = data?.map((el) => getTreeItems(el));
      setGData(treeData);
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Categories
      </Divider>
      <Form form={form}>
        {getLoading ? (
          <Skeleton active />
        ) : (
          <Form.Item name="tree2">
            <Tree
              selectable={false}
              draggable
              blockNode
              onDrop={onDrop}
              treeData={gData}
              titleRender={(data) => <div onClick={() => onSelect(data)}>{data.title}</div>}
            />
          </Form.Item>
        )}
        {selectedCategory.id ? (
          <>
            <Form.Item
              style={{ width: 400 }}
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "name is required",
                },
              ]}
            >
              <Input
                type="text"
                placeholder="name"
                value={selectedCategory.name}
                onChange={(e) => setSelectedCategory((prev) => ({ ...prev, name: e.target.value }))}
              />
            </Form.Item>
            <Form.Item
              style={{ width: 400 }}
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "description is required",
                },
              ]}
            >
              <TextArea
                rows={4}
                value={selectedCategory.description}
                onChange={(e) => setSelectedCategory((prev) => ({ ...prev, description: e.target.value }))}
              />
            </Form.Item>
          </>
        ) : null}
      </Form>
      <div className={styles.buttonContainer}>
        <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
          Add new category
        </Button>
      </div>
      <NewCategory
        refresh={refresh}
        items={gData}
        getLoading={getLoading}
        isCreateModalOpen={isCreateModalOpen}
        setIsCreateModalOpen={setIsCreateModalOpen}
      />
      <EditCategoryModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id={selectedNode?.id}
        refresh={refresh}
      />
    </div>
  );
};

export default Categories;
