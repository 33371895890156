import { Button, Form, Modal, Spin, TreeSelect } from "antd";
import { useContext, useEffect, useState } from "react";
import { useRequest } from "ahooks";
import { addGroupToCategory, getGroupById } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import type { TreeData } from "../../Categories";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Props = {
  isModalOpen: boolean;
  getCategoriesLoading: boolean;
  refresh: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  treeData: TreeData[];
};

const AddCategoryModal = ({ isModalOpen, setIsModalOpen, refresh, id, treeData, getCategoriesLoading }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [selected, setSelected] = useState(null);
  const { localCountry } = useContext(AdminContext);
  const {
    loading: getLoading,
    data: selectedGroup,
    run,
  } = useRequest(() => getGroupById(id.toString(), localCountry?.iso), {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });
  const { loading: addLoading, run: addCategory } = useRequest(addGroupToCategory, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "category was added");
      setIsModalOpen(false);
      refresh();
    },
  });

  useEffect(() => {
    if (id) {
      run();
    }
  }, [id, run]);

  if (!selectedGroup) {
    return null;
  }

  return (
    <Modal title="Add category" open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
      <Form layout="vertical" className={styles.form}>
        {getLoading ? (
          <div className={styles.spin}>
            <Spin />
          </div>
        ) : (
          <>
            <div className={styles.buttonsWrapper}>
              <Form.Item
                name="categories"
                label="Categories"
                rules={[
                  () => ({
                    validator(_, value) {
                      const existing = selectedGroup.productCategories.find((el) => el.category?.id === value);
                      if (!existing) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("This category has already been added"));
                    },
                  }),
                ]}
              >
                <TreeSelect
                  loading={getCategoriesLoading || addLoading}
                  style={{ width: 300 }}
                  treeData={treeData}
                  onSelect={(value) => setSelected(value)}
                />
              </Form.Item>
            </div>
            <div className={styles.buttonsWrapper}>
              <Button
                style={{ marginRight: 20 }}
                type="primary"
                onClick={() => addCategory(selectedGroup.id, selected, localCountry?.iso)}
                loading={addLoading}
              >
                add category
              </Button>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default AddCategoryModal;
