import { createContext } from "react";
import { notification } from "antd";

type NotificationType = "success" | "info" | "warning" | "error";
type ContextProps = {
  openNotificationWithIcon: (type: NotificationType, title: string, message?: string) => void;
};

export const NotificationContext = createContext({} as ContextProps);

export const NotificationProvider = ({ children }: { children: JSX.Element }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, title: string, message?: string) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  return (
    <NotificationContext.Provider value={{ openNotificationWithIcon }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
