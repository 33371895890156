import { useContext, useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useRequest } from "ahooks";
import { updateLanguage } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { Language } from "..";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Props = {
  refresh: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  selectedLanguage: Language;
};

const EditLanguageModal = ({ refresh, isModalOpen, setIsModalOpen, selectedLanguage }: Props) => {
  const [form] = Form.useForm();
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);

  const { loading: updateLoading, run: update } = useRequest(updateLanguage, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "country was updated");
      refresh();
      setIsModalOpen(false);
    },
  });

  useEffect(() => {
    if (selectedLanguage) {
      form.setFieldValue("name", selectedLanguage.name);
    }
  }, [form, selectedLanguage]);

  if (!selectedLanguage) return null;

  return (
    <Modal
      className={styles.modal}
      title="Update language"
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <div className={styles.container}>
        <Form
          form={form}
          className={styles.inputWrapper}
          onFinish={(values) => update(selectedLanguage.id, values.name, localCountry.iso)}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "name is required",
              },
            ]}
          >
            <Input type="text" placeholder="name" value={selectedLanguage.name} />
          </Form.Item>
          <Form.Item>
            <Button disabled={!selectedLanguage.id} type="primary" htmlType="submit" loading={updateLoading}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditLanguageModal;
