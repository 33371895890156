import { LoginOutlined } from "@ant-design/icons";
import { Button } from "antd";

import styles from "./index.module.css";

const Login = () => {
  return (
    <Button type="text" className={styles.button} icon={<LoginOutlined />}>
      Login
    </Button>
  );
};

export default Login;
