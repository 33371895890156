import { Button, Form, Input, Modal } from "antd";
import { editUser, editUserById } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import { useContext } from "react";
import { NotificationContext } from "context/notificationContext";
import { UsersContext, type User } from "context/usersContext";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Props = {
  user: User;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserModal = ({ user, isModalOpen, setIsModalOpen }: Props) => {
  const { getUsersList } = useContext(UsersContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { accountData, localCountry } = useContext(AdminContext);
  const { loading, run: update } = useRequest(editUser, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user updated");
      getUsersList(localCountry.iso);
      setIsModalOpen(false);
    },
  });
  const { loading: updateUserLoading, run: updateUser } = useRequest(editUserById, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user updated");
      getUsersList(localCountry.iso);
      setIsModalOpen(false);
    },
  });

  const onFinish = (values) => {
    if (user.id === accountData.id) {
      update({ ...values, passwordRepeat: values.password }, localCountry.iso);
    } else {
      updateUser({ ...values, passwordRepeat: values.password }, user.id, localCountry.iso);
    }
  };

  if (!user) return null;

  return (
    <Modal
      className={styles.modal}
      title="Edit user"
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <Form layout="vertical" className={styles.form} initialValues={user} onFinish={onFinish}>
        <Form.Item
          name="firstName"
          label="First name"
          rules={[
            {
              required: true,
              message: "required",
            },
          ]}
        >
          <Input size="large" type="text" placeholder="First name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last name"
          rules={[
            {
              required: true,
              message: "required",
            },
          ]}
        >
          <Input size="large" type="text" placeholder="Last name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "required",
            },
          ]}
        >
          <Input size="large" type="text" placeholder="Last name" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "required",
            },
          ]}
        >
          <Input.Password size="large" type="text" placeholder="Password" />
        </Form.Item>
        <div className={styles.buttonsWrapper}>
          <Button
            loading={loading || updateUserLoading}
            type="primary"
            style={{ width: "100%", color: "#000000", fontFamily: "Mont", marginTop: "5px" }}
            size="large"
            htmlType="submit"
          >
            Edit
          </Button>
          <Button
            loading={loading || updateUserLoading}
            onClick={() => setIsModalOpen(false)}
            type="primary"
            style={{ width: "100%", color: "#000000", fontFamily: "Mont", marginTop: "5px" }}
            size="large"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UserModal;
