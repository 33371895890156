export const columns = [
  {
    title: "Arbitr id",
    dataIndex: "arbitr",
    key: "arbitr",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Debate id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Rent id",
    dataIndex: "rentId",
    key: "rentId",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
  },
  {
    title: "Decision",
    dataIndex: "decision",
    key: "decision",
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
  },
  {
    title: "Resolved at",
    dataIndex: "resolvedAt",
    key: "resolvedAt",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
];

export const numericDateTimeOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export const formatDate = (date: string, options: Intl.DateTimeFormatOptions = numericDateTimeOptions) => {
  return date && new Date(date).toLocaleDateString("en-GB", options).replaceAll("/", ".");
};
