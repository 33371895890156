import { Button, Card } from "antd";
import LanguageForm from "../LanguageNameForm";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const initValue = { key: 1, name: 1 };

const DynamicOptions = ({ languages, initValues = [] }) => {
  const [fields, setFields] = useState([initValue]);
  const [lastKey, setLastKey] = useState(initValues.length || 1);

  const add = () => {
    setFields((prev) => [...prev, { key: lastKey + 1, name: lastKey + 1 }]);
    setLastKey((prev) => prev + 1);
  };
  const remove = (key: number) => {
    setFields((prevState) => {
      const index = prevState.findIndex((el) => el.key === key + 1);
      const newFields = [...prevState];
      if (index) {
        newFields.splice(index, 1);
      }
      return newFields;
    });
  };

  useEffect(() => {
    if (initValues.length) {
      setFields(initValues);
    }
  }, [initValues]);

  if (!languages) return null;

  return (
    <>
      {fields?.map((field) => (
        <Card
          size="small"
          title={`Selector value ${field.name}`}
          key={field.key}
          extra={<CloseOutlined onClick={() => remove(field.name - 1 || field.name)} />}
          style={{ display: "flex", rowGap: 16, flexDirection: "column", marginTop: "10px" }}
        >
          {languages?.map((language) => (
            <LanguageForm list="values" key={language.id} language={language} name={`selector value ${field.name} `} />
          ))}
        </Card>
      ))}
      <Button type="dashed" onClick={add} block>
        + Add selector value
      </Button>
    </>
  );
};

export default DynamicOptions;
