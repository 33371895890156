import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const configUrl = {
  backendUrl: process.env.NODE_ENV === "production" ? "https://c2c-api.goldapp.ru" : API_URL,
};
const UNAUTHORIZED_STATUS_CODE = 401;
const axiosApi = axios.create({
  baseURL: configUrl.backendUrl,
});

axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === UNAUTHORIZED_STATUS_CODE) {
      try {
        localStorage.removeItem("accessToken");
        window.location.href = "/login";
      } catch (refreshError) {
        window.location.reload();
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  },
);

axiosApi.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosApi;
