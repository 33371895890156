import React, { useContext, useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SlidersOutlined,
  UnorderedListOutlined,
  UserOutlined,
  GlobalOutlined,
  BarcodeOutlined,
  PieChartOutlined,
  ProductOutlined,
  NotificationOutlined,
  PartitionOutlined,
  MessageOutlined,
  HeatMapOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme, Select } from "antd";
import Login from "./Components/Login";
import AdminList from "./Components/AdminsList";
import { AdminContext } from "context/adminContext";
import Logout from "./Components/Logout";
import { NotificationProvider } from "context/notificationContext";
import Wallet from "./Components/AdminPanel/Wallet";
import Categories from "./Components/AdminPanel/Categories";
import Countries from "./Components/AdminPanel/Countries";
import Groups from "./Components/AdminPanel/Groups";
import { getContract } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import Options from "./Components/AdminPanel/Options";
import Languages from "./Components/AdminPanel/Languages";
import Products from "./Components/AdminPanel/Products";
import Cities from "./Components/AdminPanel/Cities";
import Chats from "./Components/AdminPanel/Chats";
import { Debate } from "./Components/AdminPanel/Debate";

import styles from "./index.module.css";

const { Header, Sider, Content } = Layout;

const data = [
  <Wallet key="0" />,
  <AdminList key="1" />,
  <Products key="2" />,
  <Chats key="3" />,
  <Languages key="4" />,
  <Countries key="5" />,
  <Cities key="6" />,
  <Categories key="7" />,
  <Groups key="8" />,
  <Options key="9" />,
  <Debate key="10" />,
];

const getItems = (isSupportRole: boolean) => {
  const items = [
    {
      key: "0",
      icon: <SlidersOutlined />,
      label: "Contract",
    },
    {
      key: "1",
      icon: <UserOutlined />,
      label: "Admins list",
    },
    {
      key: "2",
      icon: <ProductOutlined />,
      label: "Ads",
    },
    {
      key: "3",
      icon: <MessageOutlined />,
      label: "Chats",
    },
    {
      key: "4",
      icon: <NotificationOutlined />,
      label: "Languages",
    },
    {
      key: "5",
      icon: <GlobalOutlined />,
      label: "Countries",
    },
    {
      key: "6",
      icon: <PartitionOutlined />,
      label: "Cities",
    },
    {
      key: "7",
      icon: <UnorderedListOutlined />,
      label: "Categories",
    },
    {
      key: "8",
      icon: <BarcodeOutlined />,
      label: "Groups",
    },
    {
      key: "9",
      icon: <PieChartOutlined />,
      label: "Options",
    },
    {
      key: "10",
      icon: <HeatMapOutlined />,
      label: "Debate",
    },
  ];
  if (!isSupportRole) {
    items.splice(3, 1);
  }

  return items;
};

const MainPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { isAuthorized, setLocalCountry, localCountry, countries, accountData } = useContext(AdminContext);
  const [menuKey, setMenuKey] = useState(localStorage.getItem("menuKey") || "0");
  const isSupportRole = !!accountData?.userRoles.find((el) => {
    console.log(el);
    if (el.role === "SUPERSUPPORT" && el.approved) {
      return true;
    }
    if (el.role === "SUPPORT" && el.approved) {
      return true;
    }
    return false;
  });
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { data: contract } = useRequest(getContract);

  const menuHandler = (e) => setMenuKey(e.key);
  const countriesList = countries?.map((el) => ({ ...el, label: el.name, value: el.iso }));

  useEffect(() => {
    localStorage.setItem("menuKey", menuKey);
  }, [menuKey]);

  return (
    <NotificationProvider>
      <Layout className={styles.layout}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" />
          <Menu
            theme="dark"
            mode="inline"
            onClick={menuHandler}
            defaultSelectedKeys={[menuKey]}
            items={getItems(isSupportRole)}
          />
        </Sider>
        <Layout>
          <Header className={styles.header}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            {contract ? (
              <div className={styles.walletWrapper}>
                <div className={styles.wrapper}> Network: {contract.network}</div>
                <div className={styles.wrapper}> Balance: {contract.balance}</div>
              </div>
            ) : null}
            <div className={styles.wrapper}>
              Country:{" "}
              {countriesList && localCountry ? (
                <Select
                  loading={!countriesList}
                  style={{ width: "150px" }}
                  defaultValue={localCountry?.iso || countriesList[0]?.value}
                  placeholder="type"
                  options={countriesList}
                  onChange={(iso) => {
                    setLocalCountry(countries.find((country) => country.iso === iso));
                  }}
                />
              ) : null}
            </div>
            <div className={styles.adminData}>{accountData?.email}</div>
            <div className={styles.headerAside}>{!isAuthorized ? <Login /> : <Logout />}</div>
          </Header>
          <Content
            className={styles.content}
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {data[menuKey]}
          </Content>
        </Layout>
      </Layout>
    </NotificationProvider>
  );
};

export default MainPage;
