import { useContext, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useRequest } from "ahooks";
import { NotificationContext } from "context/notificationContext";
import { transferContract } from "helpers/backend_helper";

import styles from "./index.module.css";

const TransferContract = ({ disabled }: { disabled: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { loading, run: transfer } = useRequest(transferContract, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "transfer was success");
      setIsModalOpen(false);
    },
  });

  return (
    <>
      <Button
        disabled={disabled}
        loading={loading}
        type="primary"
        style={{ width: "100%", color: "#000000", fontFamily: "Mont", marginTop: "5px" }}
        size="large"
        onClick={() => setIsModalOpen(true)}
      >
        Transfer
      </Button>
      <Modal
        className={styles.modal}
        title="Transfer"
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          layout="vertical"
          className={styles.form}
          onFinish={(values) => transfer(Number(values.amount), values.to)}
        >
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Amount is required",
              },
            ]}
          >
            <Input size="large" type="text" placeholder="amount" />
          </Form.Item>
          <Form.Item
            name="to"
            label="To"
            rules={[
              {
                required: true,
                message: "address is required",
              },
            ]}
          >
            <Input size="large" type="text" placeholder="address" />
          </Form.Item>
          <div className={styles.buttonsWrapper}>
            <Button
              loading={loading}
              type="primary"
              style={{ width: "100%", color: "#000000", fontFamily: "Mont", marginTop: "5px" }}
              size="large"
              htmlType="submit"
            >
              Transfer
            </Button>
            <Button
              loading={loading}
              onClick={() => setIsModalOpen(false)}
              type="primary"
              danger
              style={{ width: "100%", color: "#000000", fontFamily: "Mont", marginTop: "5px" }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default TransferContract;
